.show-tooltip {
  display: inline-block;
}
.show-tooltip .show-tooltip-rel {
    display: inline-block;
    position: relative;
}
.show-tooltip .show-tooltip-popper {
    display: block;
    visibility: visible;
    font-size: 12px;
    line-height: 1.5;
    position: absolute;
    z-index: 1060;
}
.show-tooltip .show-tooltip-popper[x-placement^="top"] {
      padding: 5px 0 8px 0;
}
.show-tooltip .show-tooltip-popper[x-placement^="top"] .show-tooltip-arrows {
        margin-left: -4px;
        left: 50%;
        bottom: 5px;
        transform: rotate(225deg);
}
.show-tooltip .show-tooltip-popper[x-placement^="right"] {
      padding: 0 5px 0 8px;
}
.show-tooltip .show-tooltip-popper[x-placement^="right"] .show-tooltip-arrows {
        margin-top: -5px;
        top: 50%;
        left: 5px;
        transform: rotate(315deg);
}
.show-tooltip .show-tooltip-popper[x-placement^="bottom"] {
      padding: 8px 0 5px 0;
}
.show-tooltip .show-tooltip-popper[x-placement^="bottom"] .show-tooltip-arrows {
        margin-left: -4px;
        top: 5px;
        left: 50%;
}
.show-tooltip .show-tooltip-popper[x-placement^="left"] {
      padding: 0 8px 0 5px;
}
.show-tooltip .show-tooltip-popper[x-placement^="left"] .show-tooltip-arrows {
        margin-top: -5px;
        top: 50%;
        right: 5px;
        transform: rotate(135deg);
}
.show-tooltip .show-tooltip-popper[x-placement="top-start"] .show-tooltip-arrows {
      left: 10%;
}
.show-tooltip .show-tooltip-popper[x-placement="top-end"] .show-tooltip-arrows {
      left: 90%;
}
.show-tooltip .show-tooltip-popper[x-placement="right-start"] .show-tooltip-arrows {
      top: 30%;
}
.show-tooltip .show-tooltip-popper[x-placement="right-end"] .show-tooltip-arrows {
      top: 70%;
}
.show-tooltip .show-tooltip-popper[x-placement="left-start"] .show-tooltip-arrows {
      top: 30%;
}
.show-tooltip .show-tooltip-popper[x-placement="left-end"] .show-tooltip-arrows {
      top: 70%;
}
.show-tooltip .show-tooltip-popper[x-placement="bottom-start"] .show-tooltip-arrows {
      left: 10%;
}
.show-tooltip .show-tooltip-popper[x-placement="bottom-end"] .show-tooltip-arrows {
      left: 90%;
}
.show-tooltip .show-tooltip-inner {
    max-width: 500px;
    min-height: 34px;
    padding: 16px 18px;
    color: #63656e;
    text-align: left;
    text-decoration: none;
    background-color: #fff;
    border-radius: 2px;
    white-space: normal;
    border: 1px solid #dcdee5;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    cursor: default;
}
.show-tooltip .show-tooltip-footer {
    margin-top: 10px;
    text-align: right;
    color: #3a84ff;
}
.show-tooltip .close-tooltip-btn {
    cursor: pointer;
}
.show-tooltip .show-tooltip-arrows {
    padding-top: 4px;
    position: absolute;
    width: 8px;
    height: 8px;
    border: 1px solid #dcdee5;
    border-right-color: transparent;
    border-bottom-color: transparent;
    background-color: #fff;
    transform: rotate(45deg);
}
