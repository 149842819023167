.show-tooltip {
  height: 100%;
  display: flex;
  align-items: center;
}
.show-tooltip .bk-tooltip-ref {
    display: flex;
    align-items: center;
}
.show-tooltip-content {
  max-width: 260px;
  font-size: 12px;
}
.show-tooltip-footer {
  margin-top: 10px;
  text-align: right;
  color: #3a84ff;
}
.close-tooltip-btn {
  cursor: pointer;
}
