.bk-devops-nav-content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  border-bottom: 1px solid #dde4eb;
  background-color: #fff;
  box-shadow: 0px 2px 5px 0px rgba(51, 60, 72, 0.03);
}
.bk-devops-nav-content-header .bk-devops-navcontent-header-left {
    font-size: 16px;
}
